  .alertmessage-enter {
    opacity: 0;
    transform: scale(0.9);
    z-index: 300;
  }
  .alertmessage-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 1000ms, transform 300ms;
    z-index: 300;
  }
  .alertmessage-exit {
    opacity: 1;
    z-index: 300;
  }
  .alertmessage-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 1000ms, transform 300ms;
    z-index: 300;
  }
