.footer {
  height: 136px;
  min-width: 320px;
  box-shadow: 0px -1px 24px rgba(87, 88, 113, 0.13);
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer .footer-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.footer .footer-row .footer-about-careers {
  flex-grow: 1;
  font-weight: 500;
}

.footer .footer-row .footer-social {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.footer .footer-row .footer-margin-left {
  margin-left: 1em;
}

.footer .footer-wrap {
  flex-wrap: wrap;
}


@media (max-width: 590px) {
  .footer {
    height: 170px;
  }

  .footer .footer-row {
    flex-direction: column;
  }

  .footer .footer-row .footer-social {
    margin-top: 0.5em;
    justify-content: flex-start;
  }
}
