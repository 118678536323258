.chips {
    background-color: '#FFFFFF';
    font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  border: 2px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 0.75em;
  padding-right: 0.75em;
  width: 100%;
  cursor: pointer;
  min-height: 3em;
}

.chips::-webkit-input-placeholder {
    color: #828282;
  }

  .chips:focus {
    border: 2px solid #0060bf;
  }

  .chips:hover {
    border: 2px solid #0060bf;
  }

.chip {
  background-color: #F2F2F2;
  border-radius: 20px;
  color: black;
}

.chip .chip-value {
    color: black;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
}

.chip .chip-delete-button span {
    background-color: #f2f2f2;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    padding: 3px;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 50%;
    color: black;
}

.chip .chip-delete-button span:hover {
    background-color: #C4C4C4;
}

.chip.not-valid .chip-delete-button span{
    background: #FFEFF1;
}

.chip.not-valid .chip-delete-button span:hover{
    background: #FF586C80;
}

.chip-value {
    margin-right: 10px;
}