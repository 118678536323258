.push-enter {
    opacity: 0;
    transform: scale(0.9);
    z-index: 300;
}
  .push-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
    z-index: 300;
  }
  .push-exit {
    opacity: 1;
    z-index: 300;
  }
  .push-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
    z-index: 300;
  }